<template>
  <router-link
    :to="{ name: 'LiffOrderListShow', params: { id: order.id }, query: { source: $route.query.source } }"
    v-slot="{ navigate }"
    custom
  >
    <div class="s-order-item" @click="navigate">
      <div class="s-order-item-image" v-if="showImage && image">
        <img :src="image">
      </div>
      <div class="s-order-item-info">
        <div class="s-space-y-2 s-mr-auto">
          <p class="s-text-gray-darker s-font-semibold">訂單編號：{{ order.order_number }}</p>
          <p class="s-text-gray s-text-xs s-whitespace-nowrap">{{ datetime }}</p>
        </div>
        <div class="s-flex s-flex-col s-items-end">
          <p>
            <span class="s-tag">{{ order.order_status }}</span>
          </p>
          <p class="s-text-primary">
            <span class="s-text-sm">NT$ </span>
            <span class="s-font-bold">{{ $currency(order.amount, '') }}</span>
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
  import moment from 'moment'

  export default {
    inject: ['$currency'],
    props: {
      order: {
        type: Object,
        required: true,
      },
      showImage: Boolean
    },
    computed: {
      image () {
        return null
      },
      datetime () {
        return moment(this.order.order_at).format('YYYY-MM-DD HH:mm')
      },
    },
  }
</script>

<style scoped>
  .s-order-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--s-white);
  }

  .s-order-item-image {
    flex-shrink: 0;
    margin-right: 0.5rem;
    font-size: 0;
    width: 5rem;
    height: 5rem;
  }

  .s-order-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .s-order-item-info {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 1rem;
  }
</style>
